



















































import { Component, Vue } from 'vue-property-decorator';
import NewsListPanel from '@/components/News/NewsListPanel.vue';
import ContactPanel from '@/components/ContactPanel.vue';
import { NewsRepository } from '@/repositories/NewsRepository';
import { NewsItem } from '@/models/NewsItem';
import { OpCardList, OpCard } from '@/models/opcards/OpCard';
import * as cmsUtils from '@/common/cmsUtils';

@Component({
  components: {
    NewsListPanel,
    ContactPanel
  }
})
export default class P0207 extends Vue {
  readonly INITIAL_PAGE_NO = 1;
  readonly LIMIT_NUM = 30; // 取得するお知らせの件数
  readonly PER_PAGE: number = 10; // 1ページに表示するお知らせの件数
  newsList: Array<NewsItem> = [] as Array<NewsItem>;
  currentPage: number = this.INITIAL_PAGE_NO;

  created() {
    this.initialize();
  }
  initialize() {
    this.getNewsList();
  }

  /**
   * お知らせではロード時のみmicroCMSを呼び出す
   * ページャーを押しても呼び出しは実施せず、取得済みのお知らせリストを操作する
   */
  async getNewsList() {
    this.newsList = (
      await this.newsRepository.get({
        showPage: 'RegardlessPage',
        limit: this.LIMIT_NUM
      })
    ).filter(news => this.isPermittedEntry(news));
  }

  get getCurrentNewsList() {
    let start = (this.currentPage - 1) * this.PER_PAGE;
    let end = this.currentPage * this.PER_PAGE;
    return this.newsList.slice(start, end);
  }

  get getPaginateCount() {
    return Math.ceil(this.newsList.length / this.PER_PAGE);
  }

  get newsRepository() {
    return new NewsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  get mainOpCard() {
    if (!this.$auth.isAuthenticated) {
      return {} as OpCard;
    }
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    ).selectMainCard;
  }

  get cardType() {
    return this.$store.state.microCMS.cardType;
  }

  nextPage() {
    if (!this.isLastPage) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (!this.isFirstPage) {
      this.currentPage--;
    }
  }

  get isLastPage() {
    return this.currentPage === this.getPaginateCount;
  }

  get isFirstPage() {
    return this.currentPage === this.INITIAL_PAGE_NO;
  }

  // 表示許可券種かどうかを判定
  isPermittedEntry(news: NewsItem) {
    if (news.isPermitTargetAll) {
      return true;
    }
    if (!this.$auth.isAuthenticated) {
      return false;
    }
    if (news.isPermitTargetGroup) {
      return this.$store.state.microCMS.isMicroCMSFetched
        ? cmsUtils.isIncludedGroupCardTypes(
            this.mainOpCard.odakyuCustomerNo,
            news.permitCardTypeGroups,
            this.cardType
          )
        : false;
    }
    if (news.isPermitTargetSingle) {
      return cmsUtils.isIncludedCardTypes(
        this.mainOpCard.odakyuCustomerNo,
        news.permitCardTypes
      );
    }
    return false;
  }
}
