
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { NewsItem } from '@/models/NewsItem';

@Component
export default class NewsListPanel extends Vue {
  @Prop({ required: true })
  readonly news!: NewsItem;

  isMultipleCardType = false;

  get cardTypeMap() {
    return this.$store.state.microCMS.cardType;
  }

  get permitCardTypeText() {
    if (this.news.isPermitTargetAll) {
      return '全券種';
    }
    if (this.news.permitCardTypeText) {
      return this.news.permitCardTypeText;
    }
    if (this.news.isPermitTargetGroup) {
      this.isMultipleCardType = this.news.permitCardTypeGroups.length > 1;
      return this.news.permitCardTypeGroups[0].name;
    }
    if (this.news.isPermitTargetSingle) {
      this.isMultipleCardType = this.news.permitCardTypes.length > 1;
      return this.news.permitCardTypes[0].name;
    }
    return '';
  }

  get isNew() {
    return this.news ? this.news.isNew : false;
  }

  get url(): string {
    return '/news/' + encodeURIComponent(this.news.id);
  }
}
